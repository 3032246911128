<!-- 招生系统基础配置 -- 学校目录管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(res=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消
      ">
		
    <el-form :model="form" :rules="rules" ref="form" label-width="200rem" style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="学校正式名称" prop="name">
        <el-input v-model="form.name" class="independentStyle" maxlength="28" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="当地俗称" prop="local_name">
        <el-input placeholder="请输入" maxlength="50" v-model="form.local_name"></el-input>
      </el-form-item>
      <el-form-item label="学校识别码" prop="school_code">
        <el-input placeholder="请输入" maxlength="50" v-model="form.school_code"></el-input>
      </el-form-item>
      <el-form-item label="办学类型" prop="level">
        <el-checkbox-group v-model="form.level">
          <el-checkbox v-for="item in levelOptions" :label="item.label" :disabled="item.disabled">{{ item.text }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="学校举办者类型" prop="nature">
        <el-radio-group v-model="form.nature">
          <el-radio :label="1">公办</el-radio>
          <el-radio :label="2">民办</el-radio>
          <el-radio :label="3">民办公助</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="flex">
        <el-form-item label="学校地址" prop="province">
          <el-select v-model="form.province" placeholder="选择省份" @change="getCity">
            <el-option v-for="item in provinceOptions" :label="item.address_name" :value="item.address_code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="city" label-width="20rem">
          <el-select v-model="form.city" placeholder="选择城市" @change="getArea">
            <el-option v-for="item in cityOptions" :label="item.address_name" :value="item.address_code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="area" label-width="20rem">
          <el-select v-model="form.area" placeholder="选择区/县">
            <el-option v-for="item in areaOptions" :label="item.address_name" :value="item.address_code"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="" prop="address">
        <el-input style="width: 790rem !important;" placeholder="请输入详细地址" v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="校长姓名" prop="principal">
        <el-input placeholder="请输入" v-model="form.principal"></el-input>
      </el-form-item>
      <el-form-item label="校长联系电话" prop="mobile">
        <el-input placeholder="请输入" maxlength="20" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input placeholder="请输入" v-model="form.remark" show-word-limit="" maxlength="300" rows="8" type="textarea" resize="none" style="width: 790rem;"></el-input>
      </el-form-item>


    </el-form>
    
  
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      backType: 0,
      //办学类型配置
      levelOptions: [
        {label: 3, text: '高中', disabled: false},
        {label: 2, text: '初中', disabled: false},
        {label: 1, text: '小学', disabled: false},
      ],
      form: {
        name: '',
        local_name: '',
        level: [],
        nature: '',
        province: '',
        city: '',
        area: '',
        address: '',
        principal: '',
        mobile: '',
        remark: ''
      },
      rules: {
        name: [{required: true, message: "请输入学校正式名称"}],
        // school_code: [{required: true, message: "请输入学校识别码"}],
        level: [{required: true, message: "请选择办学类型"}],
        nature: [{required: true, message: "请选择学校举办者类型"}],
        province: [{required: true, message: "请选择学校所在省份"}],
        city: [{required: true, message: "请选择学校所在城市"}],
        area: [{required: true, message: "请选择学校所在区/县"}],
      },
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
    }
  },
  created() {
    this.getData()
    this.getProvince()
  },
  methods: {
    getData(){
      this.$_register('/api/recruit/school/' + this.$route.query.id).then(res => {
        let data = res.data.data
        data.province = data.province.toString()
        data.city = data.city.toString()
        data.area = data.area.toString()
        this.getCity(data.province)
        this.getArea(data.city)
        this.levelOptions.forEach(item => {
          if(data.level.includes(item.label)) item.disabled = true
        })
        console.log(this.levelOptions)
        this.form = data
      })
    },

    //获取省份
    getProvince(){
      this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
        this.provinceOptions = res.data.data
      })
    },

    getCity(e){
      this.form.city = ''
      this.form.area = ''
      this.areaOptions = []
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.cityOptions = res.data.data
      })
    },

    getArea(e){
      this.form.area = ''
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.areaOptions = res.data.data
      })
    },

    // 提交数据
    submit() {
      this.$refs.form.validate((res, e, p) => {
        if (res) {
          let params = {...this.form}
          this.$_register.post("/api/recruit/school/" + this.$route.query.id, {...params}).then(res => {
            this.$message({
              type: 'success',
              message: res.data.msg,
              onClose: () => {
                // this.$store.commit("setPage", 1);
                this.$router.back();
              }
            });
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.form {
  padding-top: 70rem;
}

::v-deep .el-form .el-input {
	min-width: 250rem;
}

@media only screen and (min-width: 1140rem) {
	.el-form .el-input {
		width: auto !important;
		/* min-width: 250rem; */
		max-width: 100%;
	}
}


@media only screen and (min-width: 1140rem) {
	.el-form .independentStyle.el-input {
		width: 30.13888888888888vw !important;
		/*min-width:250rem;*/
		max-width: 100%;
	}
}

@media only screen and (max-width: 1139rem) {
	.el-form .independentStyle.el-input {
		width: 500rem !important;
		max-width: 100%;
	}
}
</style>
